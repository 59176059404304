import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Typography,
  Box,
  Avatar,
  FormControlLabel,
  Switch,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import imageCompression from 'browser-image-compression'
import CustomTextField from '../components/sett/CustomTextField'
import CustomButton from '../components/CustomButton'
import { createPost, fetchUserId } from '../api/apiService'

interface CreatePostFormData {
  title: string
  content: string
  images?: File[]
  post_type: string
}

const CreatePostPage: React.FC = () => {
  const { control, handleSubmit, reset } = useForm<CreatePostFormData>()
  const navigate = useNavigate()
  const [location, setLocation] = useState<{
    latitude: number
    longitude: number
  } | null>(null)
  const [isUrgent, setIsUrgent] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  )
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        },
        (error) => console.error('Error getting location:', error)
      )
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }, [])

  const compressImage = async (file: File) => {
    const options = {
      maxSizeMB: 1, // Adjust maximum size in MB
      maxWidthOrHeight: 800, // Adjust image dimension
      useWebWorker: true,
    }
    try {
      return await imageCompression(file, options)
    } catch (error) {
      console.error('Image compression error:', error)
      return file // Fallback to original file if compression fails
    }
  }

  const onSubmit = async (data: CreatePostFormData) => {
    setLoading(true)
    try {
      const userId = await fetchUserId()

      const formData = new FormData()
      formData.append('title', data.title)
      formData.append('description', data.content)
      formData.append('is_urgent', String(isUrgent))
      formData.append('author', String(userId))
      formData.append('post_type', data.post_type)

      if (data.images && data.images.length > 0) {
        const compressedImages = await Promise.all(data.images.map(compressImage))
        compressedImages.forEach((image) => {
          formData.append('images', image)
        })
      }

      if (location) {
        formData.append('latitude', location.latitude.toFixed(6))
        formData.append('longitude', location.longitude.toFixed(6))
      }

      await createPost(formData)
      reset()
      setSnackbarMessage('Post created successfully')
      setSnackbarSeverity('success')
      setSnackbarOpen(true)
      navigate('/')
    } catch (error) {
      console.error('Error creating post:', error)
      setSnackbarMessage('Error creating post')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    } finally {
      setLoading(false)
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        margin: '0 auto',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: 'none',
      }}
    >
      <Typography variant="h6" textAlign="start" pt={2}>
        Create New Post
      </Typography>

      <Controller
        name="images"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <Box py={1} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {field.value && field.value.length > 0 && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                {field.value.map((file, index) => (
                  <Avatar
                    key={index}
                    sx={{ width: 80, height: 80, borderRadius: 1 }}
                    src={URL.createObjectURL(file)}
                  />
                ))}
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: 1,
              }}
            >
              <CustomButton variant="contained" component="label">
                Upload
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  multiple
                  onChange={(e) => {
                    if (e.target.files) {
                      field.onChange(Array.from(e.target.files))
                    }
                  }}
                />
              </CustomButton>
              <Typography variant="caption" color="textSecondary">
                Select up to 5 photos
              </Typography>
            </Box>
          </Box>
        )}
      />

      <Controller
        name="title"
        control={control}
        defaultValue=""
        rules={{ required: 'Title is required' }}
        render={({ field, fieldState }) => (
          <CustomTextField
            {...field}
            label="Title"
            variant="outlined"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        name="content"
        control={control}
        defaultValue=""
        rules={{ required: 'Content is required' }}
        render={({ field, fieldState }) => (
          <CustomTextField
            {...field}
            label="Content"
            variant="outlined"
            multiline
            rows={4}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />

      <Controller
        name="post_type"
        control={control}
        defaultValue="offer"
        render={({ field }) => (
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="post-type-label">Post Type</InputLabel>
            <Select {...field} labelId="post-type-label" label="Post Type">
              <MenuItem value="request">Request</MenuItem>
              <MenuItem value="offer">Offer</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      <FormControlLabel
        control={
          <Switch checked={isUrgent} onChange={() => setIsUrgent(!isUrgent)} />
        }
        label="Urgent"
      />

      <Button
        variant="contained"
        type="submit"
        color="secondary"
        sx={{ boxShadow: 'none', '&:hover': { boxShadow: 'none' } }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate('/')}
      >
        Cancel
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default CreatePostPage
