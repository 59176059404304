import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Tab,
  Tabs,
  Badge,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import SearchBar from '../searchBar';
import { colors } from '../../config/theme';
import AddIcon from '@mui/icons-material/Add';
import { Chat, Profile } from 'src/types/models';

interface SidebarProps {
  myId: number;
  chats: Chat[];
  activeChat: Chat | undefined;
  setActiveChat: React.Dispatch<React.SetStateAction<any>>;
  users: Profile[];
  createGroupChat: (groupName: string, selectedUsers: Array<number>) => void;
  createChat: (participants: Profile) => void;
  nearbyUsers: Profile[];
}

const Sidebar: React.FC<SidebarProps> = ({
  myId,
  chats,
  activeChat,
  setActiveChat,
  users,
  nearbyUsers,
  createGroupChat,
  createChat,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredChats, setFilteredChats] = useState(chats);
  const [groupName, setGroupName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<Array<number>>([]);
  const [selectedTab, setSelectedTab] = useState<'newChat' | 'newGroup'>('newChat');

  useEffect(() => {
    setFilteredChats(
      chats.filter((chat) =>
        getChatName(chat).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, chats]);

  const countUnreadMessages = (chat: Chat) => {
    return (
      chat.messages?.filter(
        (msg) => !msg.read_by.includes(myId) && msg.sender !== myId
      ).length || 0
    );
  };

  const getAvatar = (chat: Chat) => {
    const id = chat.participants.find((p) => p !== myId);
    const user = users.find((u) => u.id === id);
    if (chat.avatar) {
      return chat.avatar;
    } else if (user?.profile_pic && chat.participants.length === 2) {
      return user.profile_pic;
    }
    return '';
  };

  const getAvatarName = (chat: Chat) => {
    const id = chat.participants.find((p) => p !== myId);
    const user = users.find((u) => u.id === id);
    if (chat.chat_name) {
      return (chat.chat_name[0] + chat.chat_name[1]).toUpperCase();
    }
    return user ? `${user.first_name[0]}${user.last_name[0]}`.toUpperCase() : '';
  };

  const friends = users.filter((user) => user.id !== myId);

  const getChatName = (chat: Chat) => {
    const id = chat.participants.find((p) => p !== myId);
    const user = users.find((u) => u.id === id);
    if (chat.chat_name) {
      return chat.chat_name;
    } else if (user?.first_name && user?.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }
    return '';
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('Search Term:', searchTerm);
  };

  const truncateMessage = (message: string | undefined, maxLength: number) => {
    if (!message) return '';
    return message.length > maxLength ? message.slice(0, maxLength) + '...' : message;
  };

  const handleDialogToggle = () => {
    setIsDialogOpen(!isDialogOpen);
    setSelectedTab('newChat'); // Reset to 'New Chat' tab when dialog opens
  };

  const handleUserSelect = (userId: number) => {
    setSelectedUsers((prev) => {
      if (prev.includes(userId)) {
        return prev.filter((id) => id !== userId);
      }
      return [...prev, userId];
    });
  };

  const handleCreateGroup = () => {
    if (selectedUsers.length > 1) {
      createGroupChat(groupName, selectedUsers);
      setIsDialogOpen(false);
    }
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: 'newChat' | 'newGroup'
  ) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{ width: { xs: '70vw', sm: '100%', md: '25%' } }}
      pr={2}
      overflow="auto"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5" sx={{ p: '20px', fontWeight: 600 }}>
          Chat
        </Typography>
        <IconButton onClick={handleDialogToggle}>
          <AddIcon style={{ color: colors.brandBlue }} />
        </IconButton>
      </Box>
      <SearchBar
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchSubmit={handleSearchSubmit}
        backgroundColor={colors.brandGrayBG}
        paddingR="10px"
      />
      <List sx={{ pl: 1 }}>
        {filteredChats.map((chat) => (
          <ListItem
            key={chat.id}
            button
            onClick={() => setActiveChat(chat)}
            sx={{
              borderRadius: 1,
              px: 1,
              backgroundColor:
                activeChat?.id === chat.id ? colors.brandPrimary : 'transparent',
              '&:hover': {
                backgroundColor:
                  activeChat?.id === chat.id ? colors.brandPrimary : 'transparent',
                opacity: 0.8,
              },
            }}
          >
            <ListItemAvatar>
              {getAvatar(chat) ? (
                <Avatar
                  src={getAvatar(chat)}
                  sx={{
                    width: 50,
                    height: 50,
                    borderRadius: 1,
                    marginRight: 1,
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                    borderRadius: 1,
                    marginRight: 1,
                  }}
                >
                  {getAvatarName(chat)}
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={chat.chat_name || getChatName(chat)}
              secondary={truncateMessage(
                chat.messages && chat.messages.length > 0
                  ? chat.messages[chat.messages.length - 1].text
                  : 'Start chatting...',
                15
              )}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="end"
              justifyContent="space-between"
            >
              <Typography variant="caption">
                {new Date(chat.created_at).toLocaleTimeString()}
              </Typography>
              {countUnreadMessages(chat) > 0 && (
                <Badge
                  badgeContent={countUnreadMessages(chat)}
                  color="secondary"
                  sx={{
                    mr: 1,
                    mt: 1.5,
                    '& .MuiBadge-badge': {
                      backgroundColor: 'orange',
                      color: 'white',
                    },
                  }}
                />
              )}
            </Box>
          </ListItem>
        ))}
      </List>

      <Dialog
        open={isDialogOpen}
        onClose={handleDialogToggle}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Create New {selectedTab === 'newChat' ? 'Chat' : 'Group Chat'}
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab label="New Chat" value="newChat" />
            <Tab label="New Group" value="newGroup" />
          </Tabs>
          <Box pt={2}>
            {selectedTab === 'newChat' && (
              <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                {nearbyUsers.map((user) => (
                  <ListItem
                    key={user.id}
                    button
                    onClick={() => createChat(user)}
                  >
                    <ListItemAvatar>
                      {user.profile_pic ? (
                        <Avatar
                          src={`https://live-free-life-nation-back.up.railway.app${user.profile_pic}`}
                          sx={{
                            width: 50,
                            height: 50,
                            borderRadius: 1,
                            marginRight: 1,
                          }}
                        />
                      ) : (
                        <Avatar
                          sx={{
                            width: 50,
                            height: 50,
                            borderRadius: 1,
                            marginRight: 1,
                          }}
                        >
                          {`${user.first_name[0]}${user.last_name[0]}`}
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${user.first_name} ${user.last_name}`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {selectedTab === 'newGroup' && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Group Name"
                    fullWidth
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: colors.brandGray50,
                        },
                      },
                      '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                          color: colors.brandGray50,
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    value={selectedUsers}
                    onChange={(event, value) => setSelectedUsers(value)}
                    aria-label="user selection"
                    fullWidth
                  >
                    <Grid container spacing={2}>
                      {friends.map((user) => (
                        <Grid item xs={6} key={user.id}>
                          <ToggleButton
                            value={user.id}
                            selected={selectedUsers.includes(user.id)}
                            onChange={() => handleUserSelect(user.id)}
                            sx={{
                              justifyContent: 'flex-start',
                              width: '100%',
                              textAlign: 'left',
                              backgroundColor: selectedUsers.includes(user.id)
                                ? 'rgb(218,235,238)'
                                : 'transparent',
                              '&.Mui-selected': {
                                backgroundColor: 'rgb(218,235,238)',
                                '&:hover': {
                                  backgroundColor: 'rgb(218,235,238)',
                                },
                              },
                            }}
                          >
                            <ListItemAvatar>
                              {user.profile_pic ? (
                                <Avatar
                                  src={`https://live-free-life-nation-back.up.railway.app${user.profile_pic}`}
                                  sx={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 1,
                                    marginRight: 1,
                                  }}
                                />
                              ) : (
                                <Avatar
                                  sx={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 1,
                                    marginRight: 1,
                                  }}
                                >
                                  {`${user.first_name[0]}${user.last_name[0]}`}
                                </Avatar>
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${user.first_name} ${user.last_name}`}
                            />
                          </ToggleButton>
                        </Grid>
                      ))}
                    </Grid>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogToggle}
            sx={{ color: colors.brandGray50 }}
          >
            Cancel
          </Button>
          {selectedTab === 'newGroup' && (
            <Button
              onClick={handleCreateGroup}
              sx={{ color: 'black' }}
              disabled={selectedUsers.length === 0 || !groupName}
            >
              Create Group
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sidebar;
