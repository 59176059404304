import axios from 'axios'

export const BASE_URL = 'https://live-free-life-nation-back.up.railway.app'

const instance = axios.create({
  baseURL: 'https://live-free-life-nation-back.up.railway.app/', // Django backend URL
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
})
// Function to set the Content-Type header for multipart/form-data
export const setMultipartFormDataHeader = () => {
  instance.defaults.headers['Content-Type'] = 'multipart/form-data'
}

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken')
    if (token) {
      config.headers.Authorization = `Token ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
