import React, { useState, useEffect } from 'react';
import { Box, Snackbar, Skeleton } from '@mui/material';
import SuggestionProfile from './SuggestionProfile';
import { fetchNearbyUsers, updateProfile, fetchUserId, fetchUserData, fetchProfile } from '../../api/apiService'; // Adjust import path as needed
import { BASE_URL } from '../../api/axios'; // Assuming BASE_URL is exported from axios.js

interface SuggestionsProps {
  suggestionsCount?: number;
  loading: boolean;
  onLoadingComplete: () => void;
}

const Suggestions: React.FC<SuggestionsProps> = ({ suggestionsCount = 4, loading, onLoadingComplete }) => {
  const [suggestionsData, setSuggestionsData] = useState<any[]>([]);
  const [userId, setUserId] = useState<number | null>(null);
  const [username, setUsername] = useState<string>('');
  const [userFriends, setUserFriends] = useState<number[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchCurrentUserId = async () => {
      try {
        const id = await fetchUserId();
        setUserId(id);
        const userData = await fetchUserData();
        setUsername(userData.username || '');
        setUserFriends(userData.friends || []);
      } catch (error) {
        console.error('Error fetching user ID:', error);
      }
    };

    fetchCurrentUserId();
  }, []);

  useEffect(() => {
    const getNearbyUsers = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const nearbyUsers = await fetchNearbyUsers(latitude, longitude);
              if (userId !== null) {
                const profile = await fetchProfile(userId);
                const friendsList = profile.friends || [];
                setUserFriends(friendsList);

                const filteredUsers = nearbyUsers.filter((user: any) => user.id !== userId && !friendsList.includes(user.id));
                setSuggestionsData(filteredUsers.slice(0, suggestionsCount));
                onLoadingComplete();
              }
            } catch (error) {
              console.error('Error fetching nearby users:', error);
            }
          },
          (error) => {
            console.error('Error fetching location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    if (userId !== null) {
      getNearbyUsers();
    }
  }, [userId, suggestionsCount]);

  const handleFollowUnfollow = async (friendId: number, isFollowing: boolean) => {
    if (userId) {
      try {
        const currentUserProfile = await fetchProfile(userId);
        let updatedFriendsList;
        if (isFollowing) {
          updatedFriendsList = currentUserProfile.friends.filter((id: number) => id !== friendId);
        } else {
          updatedFriendsList = [...currentUserProfile.friends, friendId];
        }

        const formData = new FormData();
        updatedFriendsList.forEach((id: number) => formData.append('friends', id.toString()));

        await updateProfile(userId, formData);

        setSuggestionsData(prevData =>
          prevData.map(profile =>
            profile.id === friendId ? { ...profile, isFollowing: !isFollowing } : profile
          )
        );

        setSnackbarMessage(isFollowing ? 'Unfollowed successfully!' : 'Friend added successfully!');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error following/unfollowing user:', error);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box padding="7px 0">
      {loading ? (
        Array.from(new Array(4)).map((_, index) => (
          <Skeleton key={index} variant="rounded" width="100%" height={50} sx={{ mb: 2 }} />
        ))
      ) : (
        suggestionsData.map((profile, index) => (
          <SuggestionProfile
            key={index}
            image={profile.profile_pic ? `${BASE_URL}${profile.profile_pic}` : ''}
            firstName={profile.first_name}
            lastName={profile.last_name}
            username={username}
            isFollowing={profile.isFollowing}
            onFollowUnfollow={() => handleFollowUnfollow(profile.id, profile.isFollowing)}
          />
        ))
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  );
};

export default Suggestions;
