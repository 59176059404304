import axios from './axios' // Adjust import path as needed
import { AxiosError } from 'axios'
const API_BASE_URL = '/api'

export const fetchProfile = async (userId: number) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/profiles/${userId}/`)
    return response.data
  } catch (error) {
    console.error('Error fetching profile:', error)
    throw error
  }
}

export const updateProfile = async (userId: number, formData: FormData) => {
  try {
    const response = await axios.patch(
      `${API_BASE_URL}/profiles/${userId}/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error updating profile:', error)
    throw error
  }
}

export const authenticateUser = async (endpoint: string, data: any) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/${endpoint}/`, data)
    return response.data
  } catch (error) {
    console.error('Authentication error:', error)
    throw error
  }
}

export const fetchUserId = async () => {
  try {
    const csrftoken = getCookie('csrftoken') // Retrieve CSRF token

    const response = await axios.get(
      `https://live-free-life-nation-back.up.railway.app/dj-rest-auth/user/`,
      {
        headers: {
          'X-CSRFToken': csrftoken,
        },
      }
    )
    console.log('the user data', response.data.username)
    return response.data.pk // Assuming 'pk' is the user ID field
  } catch (error) {
    console.error('Error fetching user ID:', error)
    throw error
  }
}
export const fetchProfiles = async () => {
  try {
    const csrftoken = getCookie('csrftoken') // Retrieve CSRF token

    const response = await axios.get(
      `https://live-free-life-nation-back.up.railway.app/api/profiles/`,
      {
        headers: {
          'X-CSRFToken': csrftoken,
        },
      }
    )
    console.log('profiles', response.data)
    return response.data // Assuming 'pk' is the user ID field
  } catch (error) {
    console.error('Error fetching user ID:', error)
    throw error
  }
}
export const updateNotificationStatus = async (
  userId: number,
  status: boolean
) => {
  try {
    const response = await axios.patch(
      `${API_BASE_URL}/profiles/${userId}/`,
      { notification_on: status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error updating notification status:', error)
    throw error
  }
}

export const logoutUser = async () => {
  try {
    await axios.post(
      'https://live-free-life-nation-back.up.railway.app/dj-rest-auth/logout/'
    )
    localStorage.removeItem('authToken') // Remove token from local storage
  } catch (error) {
    console.error('Error logging out:', error)
    throw error
  }
}

function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift()
}

export const changePassword = async (newPassword: string) => {
  try {
    const response = await axios.post(
      `https://live-free-life-nation-back.up.railway.app//dj-rest-auth/password/change/`,
      {
        new_password1: newPassword,
        new_password2: newPassword,
      }
    )
    return response.data
  } catch (error) {
    console.error('Error changing password:', error)
    throw error
  }
}

export const deleteAccount = async () => {
  try {
    await axios.delete(`/api/delete-account/`)
    localStorage.removeItem('authToken') // Remove token from local storage
  } catch (error) {
    console.error('Error deleting account:', error)
    throw error
  }
}

export const updateBusinessApplication = async (formData: FormData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/business-applications/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error updating business application:', error)
    throw error
  }
}

export const createPost = async (formData: FormData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/posts/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 2000000, // Set timeout to 20 seconds
    })
    return response.data
  } catch (error) {
    console.error('Error creating post:', error)
    throw error
  }
}

export const fetchNearbyPosts = async (latitude: number, longitude: number) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-nearby-posts/`, {
      params: {
        latitude,
        longitude,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching nearby posts:', error)
    throw error
  }
}

export const fetchUserPosts = async (userId: number) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/posts/user_posts/`, {
      params: {
        user_id: userId,
      },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching user posts:', error)
    throw error
  }
}
export const fetchPostDetails = async (postId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/posts/${postId}/`)
    return response.data
  } catch (error) {
    console.error('Error fetching post details:', error)
    throw error
  }
}

export const fetchUserData = async () => {
  try {
    const csrftoken = getCookie('csrftoken') // Retrieve CSRF token

    const response = await axios.get(
      `https://live-free-life-nation-back.up.railway.app/dj-rest-auth/user/`,
      {
        headers: {
          'X-CSRFToken': csrftoken,
        },
      }
    )
    return response.data // Assuming the user data is returned in the response
  } catch (error) {
    console.error('Error fetching user data:', error)
    throw error
  }
}

export const fetchUserById = async (userId: number) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/${userId}/`)
    return response.data
  } catch (error) {
    console.error(`Error fetching user with ID ${userId}:`, error)
    throw error
  }
}

export const fetchOtherPosts = async () => {
  try {
    const response = await axios.get('/api/posts/') // Adjust the endpoint as needed
    return response.data
  } catch (error) {
    console.error('Error fetching other posts:', error)
    throw error
  }
}

export const deletePost = async (postId: number) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/posts/${postId}/`, {
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if needed (e.g., authorization headers)
      },
    })
    return response.data // Optionally return data if needed
  } catch (error) {
    throw error // Throw the error for the caller to handle
  }
}

export const updateStory = async (userId: number, formData: FormData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/stories/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error updating story:', error)
    throw error
  }
}

export const deleteStory = async () => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/delete-story/`)
    return response.data
  } catch (error) {
    console.error('Error deleting story:', error)
    throw error
  }
}

export const fetchUserStory = async (userId: number) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/stories/${userId}/`)
    return response.data
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response && error.response.status === 404) {
        return null // No active story found
      }
    }
    console.error('Error fetching user story:', error)
    throw error
  }
}

export const fetchNearbyUsers = async (latitude: number, longitude: number) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-nearby-users/`, {
      params: {
        latitude: latitude.toFixed(6), // Ensure 6 decimal places
        longitude: longitude.toFixed(6),
      },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching nearby users:', error)
    throw error
  }
}

export const fetchStories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/stories/`)
    return response.data
  } catch (error) {
    console.error('Error fetching stories:', error)
    throw error
  }
}

export const fetchChats = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/chats/`)
    return response.data
  } catch (error) {
    console.error('Error fetching chats:', error)
    throw error
  }
}

export const fetchMessages = async (chatId: number) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/messages/`, {
      params: { chat: chatId },
    })
    return response.data
  } catch (error) {
    console.error('Error fetching messages:', error)
    throw error
  }
}

export const sendMessage = async (
  chatId: number,
  message: { text?: string; sender: number; attachment?: File }
) => {
  const formData = new FormData()
  if (message.attachment) {
    formData.append('chat', chatId.toString())
    formData.append('sender', message.sender.toString())
    formData.append('text', message.attachment.name)
    formData.append('attachment', message.attachment)
  } else {
    formData.append('chat', chatId.toString())
    formData.append('sender', message.sender.toString())
    formData.append('text', message.text!)
  }
  try {
    console.log('file send1')
    const response = await axios.post(`${API_BASE_URL}/messages/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error sending message:', error)
    throw error
  }
}

export const createChat = async (formData: FormData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/chats/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error creating chat:', error)
    throw error
  }
}

export const fetchRecentlyShared = async (chatId: number) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/chats/${chatId}/recently_shared/`
    )
    return response.data
  } catch (error) {
    console.error('Error fetching recently shared items:', error)
    throw error
  }
}

export const markMessagesAsRead = async (chatId: number, userId: number) => {
  try {
    const response = await axios.post('/api/mark-messages-as-read/', {
      chat_id: chatId,
      user_id: userId,
    })
    return response.data
  } catch (error) {
    console.error('Error marking messages as read:', error)
    throw error
  }
}
