import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
} from "@mui/material";
import { Instagram, Facebook, Share } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { colors } from "../../config/theme";
import {
  fetchUserId,
  fetchProfile,
  fetchUserData,
  updateStory,
  deleteStory,
  fetchUserStory,
} from "../../api/apiService";
import CustomButton from "../../components/CustomButton";

interface Socials {
  instagram: string;
  facebook: string;
}

interface ProfileProps {
  loading: boolean;
  onLoadingComplete: () => void;
}

interface ProfileData {
  profilePic: string | undefined;
  name: string;
  email: string;
  socials: Socials;
}

const Profile: React.FC<ProfileProps> = ({ loading, onLoadingComplete }) => {
  const [profile, setProfile] = useState<ProfileData>({
    profilePic: undefined,
    name: "",
    email: "",
    socials: {
      instagram: "",
      facebook: "",
    },
  });
  const [hasActiveStory, setHasActiveStory] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const userId = await fetchUserId();
        const userData = await fetchUserData();
        const userProfile = await fetchProfile(userId);
        const { first_name, username, profile_pic } = userProfile;

        setProfile({
          profilePic: profile_pic || getInitialsAvatar(first_name, username),
          name: `${userData.first_name} ${userData.last_name}`,
          email: userData.email,
          socials: {
            instagram: "https://instagram.com",
            facebook: "https://facebook.com",
          },
        });

        const response = await fetchUserStory(userId);
        setHasActiveStory(response != null);
        onLoadingComplete();
      } catch (error) {
        console.error("Error fetching profile data:", error);
        onLoadingComplete();
      }
    }

    fetchData();
  }, [onLoadingComplete]);

  const getInitialsAvatar = (firstName: string, username: string): string => {
    const initials =
      firstName.charAt(0).toUpperCase() + username.charAt(0).toUpperCase();
    return initials;
  };

  const handleProfilePicChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  const handleConfirm = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", selectedFile);

      try {
        const userId = await fetchUserId();
        const userData = await fetchUserData();
        formData.append("user", userId.toString());

        await updateStory(userId, formData);

        const userProfile = await fetchProfile(userId);
        setProfile((prevProfile) => ({
          ...prevProfile,
          profilePic: userProfile.profile_pic,
        }));

        setHasActiveStory(true);
      } catch (error) {
        console.error("Error updating story:", error);
      } finally {
        setOpen(false);
        setSelectedFile(null);
      }
    }
  };

  const handleDeleteStory = async () => {
    try {
      await deleteStory();
      setHasActiveStory(false);
    } catch (error) {
      console.error("Error deleting story:", error);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="start"
      justifyContent="space-between"
      mt={4}
    >
      <Box display="flex" alignItems="start">
        <Box position="relative" display="inline-block">
          {loading ? (
            <Skeleton variant="rounded" width={120} height={120} />
          ) : (
            <Avatar
              src={profile.profilePic}
              sx={{ width: 120, height: 120, borderRadius: "7px" }}
            />
          )}
          {!hasActiveStory && !loading && (
            <Box
              position="absolute"
              bottom={0}
              left={0}
              width="100%"
              height="70%"
              display="flex"
              borderRadius="0 0 4px 7px"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{
                cursor: "pointer",
                transition: "opacity 0.3s",
                opacity: 1,
                background:
                  "linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)",
                "&:hover": {
                  opacity: 0.7,
                },
              }}
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleProfilePicChange}
                />
                <AddIcon fontSize="large" sx={{ color: "white" }} />
              </IconButton>
              <Typography variant="caption" color="white" fontSize="14px">
                Add Story
              </Typography>
            </Box>
          )}
        </Box>
        <Box ml={2}>
          {loading ? (
            <>
              <Skeleton variant="text" width={150} height={45} />
              <Skeleton
                variant="text"
                width={200}
                height={35}
                sx={{ mt: 0.5 }}
              />
            </>
          ) : (
            <>
              <Typography variant="h6">{profile.name}</Typography>
              <Typography variant="body1" color={colors.brandGray50} mt={0.5}>
                {profile.email}
              </Typography>
              {hasActiveStory && (
                <CustomButton
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 2 }}
                  onClick={handleDeleteStory}
                >
                  Delete Story
                </CustomButton>
              )}
              <Box mt={1} display="none" alignItems="center">
                <IconButton
                  href={profile.socials.instagram}
                  aria-label="Instagram"
                >
                  <Instagram sx={{ width: 34, height: 34 }} />
                </IconButton>
                <IconButton
                  href={profile.socials.facebook}
                  aria-label="Facebook"
                >
                  <Facebook sx={{ width: 34, height: 34 }} />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Button
        variant="outlined"
        startIcon={<Share />}
        sx={{
          boxShadow: "none",
          display: { sm: "flex", xs: "none", md: "flex" },
          color: "#000",
          fontSize: "11px",
          padding: "6px 12px",
          ml: 2,
          border: "1px solid black",
          "&:hover": {
            borderColor: "black",
            opacity: 0.75,
          },
        }}
      >
        Share
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Story</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to upload this story?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;
