import React, { useRef } from 'react';
import { Container, Grid, Typography, Box, IconButton, Skeleton } from '@mui/material';
import GiveawayMain from '../components/giveaway/GiveawayMain';
import GiveawayList from '../CompLists/GiveawayList';
import EventItemsList from '../CompLists/EventItemsList';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useGiveaways from '../hooks/useGiveaways';
import useEvents from '../hooks/useEvents';

const GiveawaysPage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { giveaways, loading: loadingGiveaways, error: errorGiveaways } = useGiveaways();
  const { events, loading: loadingEvents, error: errorEvents } = useEvents();

  const scroll = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth * 0.32;
      containerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  if (errorGiveaways || errorEvents) {
    return <Typography>Error loading data</Typography>;
  }

  return (
    <Container sx={{ mt: 3, padding: '20px', mb: 2 }} disableGutters>
      {loadingGiveaways ? (
        <Skeleton variant="text" width={200} height={40} sx={{ mb: 2 }} />
      ) : (
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
          Giveaways
        </Typography>
      )}

      <Grid container spacing={2} mt={2} mb={4}>
        <Grid item xs={12} md={6}>
          {loadingGiveaways ? (
            <Skeleton variant="rounded" width="100%" height={270} />
          ) : (
            <GiveawayMain giveaways={giveaways} />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {loadingGiveaways ? (
            <Grid container spacing={2}>
              {Array.from(new Array(4)).map((_, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Skeleton variant="rounded" width={"100%"} height={120} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <GiveawayList giveaways={giveaways} />
          )}
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        {loadingEvents ? (
          <Skeleton variant="text" width={200} height={40} />
        ) : (
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Featured events
          </Typography>
        )}
        {loadingEvents ? (
          <Box display="flex" alignItems="center">
            <Skeleton variant="rounded" width={60} height={30} sx={{ mr: 1 }} />
            <Skeleton variant="rounded" width={60} height={30} />
          </Box>
        ) : (
          <Box>
            <IconButton onClick={() => scroll('left')} sx={{ borderRadius: '2px', px: 2.5, py: 0.5, backgroundColor: '#fff', border: '1px solid #ccc' }}>
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => scroll('right')} sx={{ borderRadius: '2px', px: 2.5, py: 0.5, backgroundColor: '#000', border: '1px solid #000', marginLeft: '8px', '&:hover': { backgroundColor: '#333', borderColor: '#333' } }}>
              <ArrowForwardIosIcon fontSize="small" sx={{ color: 'white' }} />
            </IconButton>
          </Box>
        )}
      </Box>

      {loadingEvents ? (
        <Grid container spacing={2}>
          {Array.from(new Array(3)).map((_, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Skeleton variant="rounded" width="100%" height={200} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EventItemsList containerRef={containerRef} events={events} />
      )}
    </Container>
  );
};

export default GiveawaysPage;
