// File: /App.tsx

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./themeoptions";
import ProtectedRoute from "./hoc/ProtectedRoute";
import LeftDrawer from "./components/LeftDrawer";

import ProfilePage from "./pages/ProfilePage";
import MessagesPage from "./pages/MessagesPage";
import ShopPage from "./pages/ShopPage";
import GiveawaysPage from "./pages/GiveawaysPage";
import SettingsPage from "./pages/SettingsPage";
import HomePage from "./pages/HomePage";
import PostDetailsPage from "./pages/PostDetailsPage";
import CreatePostPage from "./pages/CreatePostPage";
import LandingPage from "./pages/LandingPage";

import FeedHelper from "./helpers/FeedHelper";
import ProfileHelper from "./helpers/ProfileHelper";
import MessagesHelper from "./helpers/MessagesHelper";
import GiveawaysHelper from "./helpers/GiveawaysHelper";
import SettingsHelper from "./helpers/SettingsHelper";
import CommunHelper from "./helpers/CommunHelper";

import withLocationPermission from "./hoc/withLocationPermission";
import { Box } from "@mui/material";
import PublicRoute from "./hoc/PublicRoute";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Protected Routes */}
          <Route element={<PublicRoute />}>
            <Route path="/home" element={<LandingPage />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route index path="/*" element={<ProtectedLayout />} />
          </Route>

          {/* Redirect all unknown routes to /home */}
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

const ProtectedLayout: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation();

  const isMessagesPage = location.pathname === "/messages";
  const isShopPage = location.pathname === "/shop";

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  React.useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname]);

  // Wrap each page component with location permission HOC
  const ProtectedProfilePage = withLocationPermission(ProfilePage);
  const ProtectedMessagesPage = withLocationPermission(MessagesPage);
  const ProtectedShopPage = withLocationPermission(ShopPage);
  const ProtectedGiveawaysPage = withLocationPermission(GiveawaysPage);
  const ProtectedSettingsPage = withLocationPermission(SettingsPage);
  const ProtectedHomePage = withLocationPermission(HomePage);
  const ProtectedCreatePostPage = withLocationPermission(CreatePostPage);
  const ProtectedPostDetailsPage = withLocationPermission(PostDetailsPage);

  // Wrap helper components with location permission if necessary
  const CommunHelperWithPermission = withLocationPermission(CommunHelper, true);
  const FeedHelperWithPermission = withLocationPermission(FeedHelper, true);
  const ProfileHelperWithPermission = withLocationPermission(
    ProfileHelper,
    true,
  );
  const GiveawaysHelperWithPermission = withLocationPermission(
    GiveawaysHelper,
    true,
  );
  const SettingsHelperWithPermission = withLocationPermission(
    SettingsHelper,
    true,
  );

  return (
    <div className="app-container">
      {/* Left Drawer */}
      <div className={`left-drawer ${drawerOpen ? "open" : ""}`}>
        <LeftDrawer />
      </div>

      {/* Hamburger Icon */}
      <div
        className={`hamburger ${drawerOpen ? "open" : ""}`}
        onClick={toggleDrawer}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Main Content */}
      <div className="main-content" onClick={() => setDrawerOpen(false)}>
        <Routes>
          <Route index path="/" element={<ProtectedHomePage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/profile" element={<ProtectedProfilePage />} />
          <Route path="/messages" element={<ProtectedMessagesPage />} />
          <Route path="/shop" element={<ProtectedShopPage />} />
          <Route path="/giveaways" element={<ProtectedGiveawaysPage />} />
          <Route path="/settings" element={<ProtectedSettingsPage />} />
          <Route path="/create-post" element={<ProtectedCreatePostPage />} />
          <Route path="/post/:postId" element={<ProtectedPostDetailsPage />} />
        </Routes>
      </div>
      {/* Right Helper */}
      {!isMessagesPage && !isShopPage && (
        <div className="right-helper">
          <Routes>
            <Route path="/profile" element={<CommunHelperWithPermission />} />
            <Route path="/giveaways" element={<CommunHelperWithPermission />} />
            <Route path="/settings" element={<CommunHelperWithPermission />} />
            <Route
              path="/create-post"
              element={<CommunHelperWithPermission />}
            />
            <Route
              path="/post/:postId"
              element={<CommunHelperWithPermission />}
            />
            <Route path="/" element={<FeedHelperWithPermission />} />
          </Routes>
        </div>
      )}
    </div>
  );
};

export default App;
